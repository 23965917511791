import React from "react"
import styled from "styled-components"
import Image from "gatsby-image"
import { graphql } from "gatsby"

const StyledHeroImage = styled(Image)`
  width: 100%;
  margin: 0 auto;
`

const StyledContentWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
`

const StyledSectionTitle = styled.h2`
  color: #da1e35;
  font-size: 32px;
  text-align: center;
  padding: 0 20px;
`

const StyledSection = styled("div")`
  display: flex;
  flex-direction: column;

  p {
    color: #000;
    font-size: 15px;
    padding: 0 20px;
    text-align: justify;
  }
`

const StyledListParagraph = styled.p`
  color: #000;
  font-size: 15px;
  padding: 0 20px;
  text-align: justify;
  margin: 5px 0;
`

const TreatmentPage = ({ data }) => {
  return (
    <>
      <StyledHeroImage fluid={data.hero_image_painting.childImageSharp.fluid} />
      <StyledContentWrapper
        image={data.maps_background.childImageSharp.fluid.src}
      >
        <StyledSection>
          <StyledSectionTitle>OBRÓBKA</StyledSectionTitle>
          <p>Usługi z zakresu obróbki blach obejmują m.in.:</p>
          <StyledListParagraph>– formatowanie,</StyledListParagraph>
          <StyledListParagraph>– wykrawanie,</StyledListParagraph>
          <StyledListParagraph>– gięcie,</StyledListParagraph>
          <StyledListParagraph>– tłoczenie,</StyledListParagraph>
          <StyledListParagraph>– obróbkę plastyczną.</StyledListParagraph>
        </StyledSection>
        <StyledSection>
          <p>
            Oferta zawiera także produkty z włóknin i tworzyw sztucznych, takie
            jak:
          </p>
          <StyledListParagraph>
            – uszczelki z włókniny poliestrowej,
          </StyledListParagraph>
          <StyledListParagraph>– uszczelki filcowe,</StyledListParagraph>
          <StyledListParagraph>
            – wkłady dźwiękochłonne z tworzywa typu „POROSO” (również w wersji z
            warstwą klejową – samoprzylepną),
          </StyledListParagraph>
          <StyledListParagraph>
            – elementy izolacyjne z folii samogasnącej,
          </StyledListParagraph>
          <StyledListParagraph>– woreczki polietylenowe,</StyledListParagraph>
          <StyledListParagraph>
            – wkłady filtrujące i wygłuszające z gąbek poliuretanowych oraz
            polietylenowych,
          </StyledListParagraph>
          <StyledListParagraph>
            – gąbki uszczelniające poliuretanowe i polietylenowe,
          </StyledListParagraph>
          <StyledListParagraph>– przewody,</StyledListParagraph>
          <StyledListParagraph>
            – folie zabezpieczające z Mylaru oraz polipropylenu,
          </StyledListParagraph>
          <StyledListParagraph>
            – węże elastyczne na bazie PCV.
          </StyledListParagraph>
        </StyledSection>
      </StyledContentWrapper>
    </>
  )
}

export const query = graphql`
  {
    maps_background: file(name: { eq: "maps_background" }) {
      publicURL
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    hero_image_painting: file(name: { eq: "hero_image_painting" }) {
      publicURL
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

export default TreatmentPage
